.transparent {
  --background: transparent !important;
  background: transparent !important;
  box-shadow: none;
}

.label{
  color: var(--nameForm);
}

.h-100{
  height: 100% !important;
}

.divisorTime{
  width: 97%;
  position: absolute;
  top: 0;
  background: rgb(47, 187, 34);
  border-radius: 4px;
  opacity: .8;
  margin-left: 2.5px;
}

:host-context(.item-has-focus.item-label-stacked) .select-icon{
  transform: translate3d(-10px, 0px, 0px) rotate(180deg) !important;
}

.np{
  padding: 0 !important;
}

.zoomContainer{
  --background: transparent;
  backdrop-filter: blur(1.5px);
}

.container-list-qualification.max-cal{
  label.star{
    &::before{
      // content: '';
      text-shadow: 0 0 10px #952 !important;
    }
  }
}

.login-modal{
  ion-card{
    height: 100%;
  }
}

.login-header{
  --backdrop-opacity: 0;
  justify-content: flex-start;
  --border-radius: 0;
  --box-shadow: 0 2px 5px rgba(0,0,0,.3);
  --background: var(--modals);
}


@media screen and (min-width: 576px) {
  .login-header{
    --width: calc(100% / 2);
    align-items: baseline;
    margin-top: 4rem;
  }
}

.popover-wide .alert-wrapper {
  width: 320px;
}

@media screen and (min-width: 768px) {
  .login-header{
    --width: calc(100% / 4);
  }
}


.container-list-qualification{
  width: max-content;
 
  label.star{
    pointer-events: none;
    padding: 5px !important;
    font-size: 1.05rem !important;
  }
}
.container-list-qualification.store{
  width: max-content;
  label.star{
    pointer-events: none;
    padding: 5px !important;
    font-size: 2rem !important;
  }
}

.hw-100{
  min-height: calc(100vh - 4.8rem);
}

.loading{
  --background: var(--modals);
  color: var(--description);
}

.m-auto{
  margin: auto;
}

.noShadow{
  box-shadow: none !important;
  --box-shadow: none !important;
}

.btns-admin{
  font-size: 12px;
  text-transform: initial;
}

.alert-tappable.sc-ion-alert-md{
  min-height: 48px !important;
  contain: content !important;
  height: auto !important;
}

.acc-container.accordion-expanded, .acc-container.accordion-expanding{
  .ion-accordion-toggle-icon{
    color: var(--btnHeader) !important;
  }
}

.ion-accordion-toggle-icon{
  color: var(--btnHeader) !important;
}

.acc-header-item{
  .ion-accordion-toggle-icon{
    color: var(--description) !important;
  }
}

.report-group{
  .report-group_header{
    .ion-accordion-toggle-icon{
      color: var(--title) !important;
    }
  }
}

.ocultBadge{
  width: 0px;
  min-width: 0;
  transition: all ease .5s;
  opacity: 0;
  --padding-end: 0;
  --padding-start: 0;
}

.showBadge{
  --padding-end: 8px;
  --padding-start: 8px;
  opacity: 1;
  width: 32px;
}

.boldText{
  font-weight: bold !important;
}

.noBorder{
  border: none;
}

.btn-aboutme {
  --border-radius: 12px !important;
  --box-shadow: 0px 2px 5px rgb(57 117 1 / 34%);
  border-radius: 12px !important;
  background: #fff;
  text-transform: capitalize;
  margin: 20px 0 5px .1rem;
  box-shadow: none;
}

@media screen and (min-width: 1000px) {
  .pasarela-pago {
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    --max-width: 30% !important;
    --min-height: 90vh;
    justify-content: right;
  }
}

@media screen and (min-width: 578px){
  .pasarela-pago{
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    --min-height: 90vh;
    justify-content: right;
  }
}



.more{
  font-size: 1.2rem;
  --padding-start: 1.5rem;
  --padding-end: 1.5rem;
  ion-label{
    text-transform: initial;
  }
}

.w-100{
  flex-basis: 100% !important;
  width: 100% !important;
}

.titleCard{
  font-size: 1.5rem;
  font-weight: 800;
}


.dateEvent{
  cursor: pointer;
  position: relative;
  &::before{
    content: 'X';
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
  }
}


.subtitles{
  font-size: 1.8rem;
  font-weight: 800;
}

@media screen and (min-width: 660px){
  .titleCard{
    font-size: 2rem;
  }
}

.bgCard{
  background-color: var(--card);
}

.pager {
  margin: 3.5rem 0 !important;
  display: flex;
  justify-content: center;
  margin: auto;
}

.toastMsg{
  --background: var(--header);
  --color: var(--btnHeader);
  color: var(--btnHeader);
}

@media screen and (max-width: 470px){
  .toastMsg{
    --width: 100vh;
    --border-radius: 0px;
    --min-height: 50px;
    --start: 0;
    --ion-safe-area-bottom: -11px;
  }
}

.noPadding{
  padding: 0;
}

.splash{
  --width: 100vw;
  --height: 100vh;
}

.pointer{
  cursor: pointer !important;
}

.d-flex{
  display: flex;
}

.modalRegister{
  --height: 35%;
  --min-height: 0%;
  --border-radius: 1.8rem;
  --backdrop-opacity: .2;
  --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
  --width: 95%;
}
.toolbarUser{
  .searchbar-input{
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}

.notification{
  --min-width: 100%;
  --offset-x: 12.5px;
}

@media screen and (min-width: 578px) {
  .notification{
    --min-width: 400px;
    --backdrop-opacity: 0;
    --offset-x: -1rem;
  }
  .modalRegister{
    --height: 35%;
    --min-height: 0%;
    --border-radius: 1.8rem;
    --backdrop-opacity: .2;
    --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
    --width: 30%;
  }

  .modalAdmin{
    --height: 60%;
    --min-height: 56%;
    --border-radius: 1.8rem;
    --backdrop-opacity: .2;
    --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
    --width: 55%;
  }
  
 

}

.forgottPass{
  --background: transparent !important;
  --box-shadow: none;
}

.modalColor{
  background-color: var(--modals);
  --background: var(--modals);
}

.indexSup{
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 578px) {
  .centerPopover{
    --max-height: 400px;
    --min-width: 50%;
    --offset-y: -25%;
    --backdrop-opacity: .2;
  }
  
}

.centerPopover{
  --max-height: 100%;
}

.noOpacity{
  --backdrop-opacity: 0.1;
}

.color-icon{
  --color: var(--icons);
  color: var(--icons);
}

.swiper-pagination{
  .swiper-pagination-bullet{
    margin: 0 8px !important;
    opacity: .5;
  }
  .swiper-pagination-bullet-active{
    opacity: 1;
  }
}

ion-content{
  --background: var(--home)
}

.sections_content{
  .content_catalogue-text{
    text-align: center;
    padding-top: 1.5rem;
  }
}

.title{
  color: var(--title) !important;
  --color: var(--title) !important;
  // padding-left: 1rem;
}

@media screen and (max-width: 420px) {
  .title{
    font-size: 1.2rem;
  }
  
  .description{
    font-size: .8rem;
  }
}
.fc-toolbar-title{
  color: var(--description) !important;
}
.disableDayOfCalendar{
  background: #ccc;
  cursor: not-allowed !important;
}

.description{
  color: var(--description) !important;
  --color: var(--description) !important;
}

.d-block{
  display: block;
}

.fullWdModal{
  --width: 100%;
  --height: 100%;
}

.searchbar{
  .searchbar-search-icon.sc-ion-searchbar-md {
    left: auto;
    z-index: 2;
    right: 16px;
    color: #000;
  }
}

.popover-wide {
  ion-radio {
    --color-checked: var(--title) !important;
  }
}

.searchBarUser{
  border-right: 1.5px solid #aaa;
  border-left: 1.5px solid #aaa;
  .searchbar-search-icon.sc-ion-searchbar-md {
    display: none !important;
  }
}

.register-btn{
  width: fit-content;
  margin: auto;
  .border-bottom{
    border-bottom: 1px solid #fff;
  }
}

.ngx-datatable.material{
  border-radius: .7rem .7rem 0 0;
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
  .datatable-header{
    height: auto !important;
    .datatable-header-cell{
      background-color: var(--header);
      .sort-btn{
        color: var(--icons);
      }
    }
    .datatable-header-cell-label{
      color: var(--btnHeader)
    }
  }
  .datatable-body-row{
    height: auto !important;
    .datatable-body-cell{
      height: auto !important;
      border-bottom: 1px solid #ccc;
      .datatable-body-cell-label{
        span[title~="Activo"], span[title~="Inactivo"], span[title~="Verificado"], span[title~="No verificado"]{
          cursor: pointer;
          position: relative;
          color: #fff;
          padding: .5rem 1rem;
          z-index: 2;
          &:hover::before{
            opacity: .9;
          }
          &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .7;
            border-radius: 8px;
            z-index: -1;
            transition: opacity .5s ease;
            background-color: var(--primary--background);
          }
        }
        span[title~="Activo"]{
          &::before{
            background-color: var(--primary--background);
          }
        }
        span[title~="Inactivo"]{
          &::before{
            background-color: var(--primary--background);
          }
        }
      }
    }
  }
}

.rolSpan{
  cursor: pointer;
  position: relative;
  color: #fff;
  padding: .5rem 1rem;
  z-index: 2;
  &:hover::before{
    opacity: .9;
  }
  &::before{
    content: '';
    background-color: var(--primary--background);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .7;
    border-radius: 8px;
    z-index: -1;
    transition: opacity .5s ease;
  }
}

.popoverActivo{
  --backdrop-opacity: 0;
  --width: auto;
  --box-shadow: 0 2px 6px #40404066;
}

.checkbox{
  --color-checked: var(--title);
  --background-checked: var(--title);
  --border-color-checked: var(--buttons);
  margin-right: .8rem;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield !important;
}

.checkboxInput{
  --background-checked: var(--primary--background);
  --border-color-checked: var(--primary--background);
  --background: transparent;
  --color: #000;
  --border-color: #ccc;
  margin-right: .8rem;
}

:root{
  .select-popover{
    .sc-ion-select-popover-md{
      white-space: normal;
    }
  }
  :host-context(.item-has-focus){
    transform: translate3d(-10px, 0px, 0px) rotate(180deg) !important;
  }
}


.header{
  background-color: var(--header);
  background: var(--header);
}

.headerCard{
  background-color: var(--title);
}

.headerBtn{
  color: var(--btnHeader) !important;
}

.successComponent{
  --width: 90%;
  --height: 30%;
  --border-radius: 1.2rem;
}

@media screen and (min-width: 769px){
  .successComponent{
    --width: 35%;
    --height: 35%;
    --border-radius: 1.2rem;
  }
}

.select-search{
  color: #121212;
  --background: rgb(243 243 243);
  --icon-color: #121212;
  --box-shadow: inherit;
}

@media screen and (max-width: 530px) {
  .tached{
    font-size: 12px;
  }
}
.tached{
  text-decoration: line-through;
  opacity: .8;
  font-weight: normal !important;
}

.segment-container{
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
  --background: var(--header);
  .segment-btn{
    text-transform: initial;
    font-size: 1rem;
    --background-hover: var(--primary--background);
    --color-hover: var(--primary--color);
    --background-hover-opacity: .8;
    color: var(--btnHeader) !important;
  }
  .segment-button-checked{
    --indicator-color: transparent;
    --color-checked: var(--primary--color);
    background: var(--primary--background);
    min-width: auto !important;
    color: var(--primary--color) !important;
    font-weight: 400;
  }
}

.out-of-service{
  background-color: #f00 !important;
  background: #f00 !important;
}

.fc-blocked-business{
  background-color: #00000036;
  background: var(--fc-non-business-color, rgba(215, 215, 215, 0.5));
}

.underline{
  text-decoration: underline;
}

.alert-radio-label, .alert-checkbox-label{
  white-space: normal !important;
}

.alert-wrapper.sc-ion-alert-md{
  background: var(--card) !important;
  h2, .alert-radio-label, .alert-checkbox-label{
    color: var(--description) !important;
  }
}

#calendar{
  .unAvailableDate{
    cursor: no-drop !important;
    background: #bcbcbc80 !important;
  }
  
  .fc-col-header-cell.fc-day{
    .fc-scrollgrid-sync-inner{
      background: var(--header);
      .fc-col-header-cell-cushion{
        color: var(--btnHeader);
      }
    }
  }
  .fc-daygrid-day-number{
    color: var(--description) !important;
  }
  .fc-timegrid-slot-label{
    color: var(--description) !important;
  }
  .fc-daygrid-day{
    cursor: pointer;
    &:hover{
      background: #7272721b;
    }
  }
  .fc-timegrid-col-events{
    z-index: inherit;
    .fc-timegrid-event-harness:has(> a.dateEvent){
      z-index: 1 !important;
    }
    .fc-timegrid-event-harness{
      z-index: 0 !important;
    }
  }
  
  .fc-button-primary{
    background: var(--primary--background) !important;
    box-shadow: var(--primary--box-shadow) !important;
    color: var(--primary--color) !important;
    &:hover{
      opacity: .9;
    }
  }
  
  .fc-non-business{
    background-color: #00000036;
    &:hover{
      cursor: no-drop;
    }
  }

  .reservedEvent{
    cursor: no-drop;
    .fc-event-title{
      font-weight: bold;
    }
  }
  

  .fc-timegrid-slot:hover{
    cursor: pointer !important;
    background: #cccccc1a;
  }
  
  .fc-button-primary:not(:disabled).fc-button-active{
    opacity: .8;
  }
  
  .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today, 
  .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end{
    background-color: var(--header);
    color: var(--btnHeader);
  }
}